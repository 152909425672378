<template>
    <modal ref="modalAprobarCambioPrecio" titulo="Aprobar cambio de precio" tamano="modal-lg" no-aceptar adicional="Aprobar" @adicional="aprobar">
        <div class="row mx-0 align-items-center justify-center my-3">
            <p class="col-auto f-16">
                <b class="text-general">
                    Precio Original:
                </b>
                <span class="text-general2 ml-1">
                    {{ separadorNumero(sugerencia.unidad_teorico, pedido.decimales_moneda) }}
                </span>
            </p>
            <p class="col-auto f-16">
                <b class="text-general">Precio Final:</b>
                <span class="text-general2 ml-1">
                    {{ separadorNumero(sugerencia.unidad_sugerida, pedido.decimales_moneda) }}
                </span>
            </p>
        </div>
        <div class="row mx-0 align-items-center justify-center my-3">
            <div class="col-4">
                <p class="text-general f-15 pl-3">
                    Nuevo Precio
                </p>
                <div class="d-middle">
                    <money v-model="model.unidad_sugerida" class="input-money" v-bind="money" />
                    <span class="border p-1 br-r-10 bg-gris f-600" style="margin-left: -8px; width: 50px; height:32px;">{{ pedido.sigla_moneda }}</span>
                </div>
            </div>
            <div class="col-6">
                <p class="text-general f-15 pl-3">
                    Motivo de cambio de precio
                </p>
                <el-select v-model="model.id_motivo_solicitud" placeholder="Seleccionar" size="small" class="w-100">
                    <el-option
                    v-for="item in motivos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 justify-center my-4 mb-5">
            <div class="col-10">
                <p class="f-15 text-general pl-3">
                    Observación <span class="f-11">(Opcional)</span>
                </p>
                <el-input v-model="model.justificacion" class="w-100" placeholder="Observacion..." type="textarea" :rows="4" maxlength="500" show-word-limit />
            </div>
        </div>
    </modal>
</template>

<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
import Motivos from '~/services/pedidos/pedidos-tienda'
import Pedidos from '~/services/pedidos/pedidos-admin'
export default {
    components:{
        Money,
    },
    props:{
        sugerencia:{
            type:Object,
            required:true
        }
    },
    data(){
        return{
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            motivos: [],
            model:{
                id_motivo_solicitud:null,
                justificacion:'',
                unidad_sugerida:0
            },

        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
        }),
    },
    methods: {
        toggle(){
            this.model.id_motivo_solicitud = this.sugerencia.id_motivo_solicitud
            this.model.unidad_sugerida = parseFloat(this.sugerencia.unidad_sugerida)
            this.money.precision = this.pedido.decimales_moneda
            this.model.justificacion = ''
            if(this.motivos.length === 0){
                this.select_motivos()
            }
            this.$refs.modalAprobarCambioPrecio.toggle()
        },
        async aprobar(){
            try {

                const {data} = await Pedidos.aprobar_solicitud(this.sugerencia.id, this.model)
                this.notificacion('Éxito','Solicitud de cambio de precio Aprobada','success')
                //this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.id_pedido))
                this.$emit('updateTable')
                this.$refs.modalAprobarCambioPrecio.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        async select_motivos(){
            try {
                let params = { tipo:21 }
                const {data} = await Motivos.select_motivos(params)
                this.motivos = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
